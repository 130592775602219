<template>
  <div>
    <b-table
      bordered
      hover
      :fields="fields"
      :items="items"
      v-if="renderComponent"
    >
      <template #thead-top="">
        <b-tr>
          <b-th></b-th>
          <b-th>
            <vue-autosuggest
              v-model="mainModel.rewardCateName"
              :suggestions="filteredOptions"
              @selected="onSelected"
              :limit="10"
              @input="onInputChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Nhập tên nhóm sản phẩm',
                style: 'border-radius:0px!important',
              }"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading
              "
            >
              <template slot-scope="{ suggestion }">
                <div>{{ suggestion.item.name }}</div>
              </template>
            </vue-autosuggest>
          </b-th>
          <b-th>
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="mainModel.name"
            ></b-input>
          </b-th>
          <b-th>
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="mainModel.description"
            ></b-input>
          </b-th>
          <b-th>
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="mainModel.amount"
              v-mask="mask"
            ></b-input>
          </b-th>
          <b-th>
            <b-form-select
              size="sm"
              v-model="mainModel.type"
              :options="listType"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-th>
          <b-th>
            <div class="d-flex justify-content-center">
              <b-button @click="onAddFee(mainModel)">
                <b-icon
                  style="font-size: 1rem; padding-right: 0px"
                  icon="plus-circle"
                ></b-icon>
              </b-button>
            </div>
          </b-th>
        </b-tr>
      </template>

      <template v-slot:cell(name)="row">
        <b-input
          type="text"
          size="sm"
          class="input-style text-right"
          v-model="row.item.name"
        ></b-input>
      </template>
      <template v-slot:cell(rewardCateName)="row">
        <b-input
          type="text"
          size="sm"
          class="input-style text-right"
          v-model="row.item.rewardCateName"
          :disabled="true"
        ></b-input>
      </template>
      <template v-slot:cell(description)="row">
        <b-input
          type="text"
          size="sm"
          class="input-style text-right"
          v-model="row.item.description"
        ></b-input>
      </template>
      <template v-slot:cell(amount)="row">
        <b-input
          type="text"
          size="sm"
          class="input-style text-right"
          v-model="row.item.amount"
          v-mask="mask"
        ></b-input>
      </template>
      <template v-slot:cell(type)="row">
        <b-form-select v-model="row.item.type" size="sm">
          <b-form-select-option :value="1"> Chi phí </b-form-select-option>
          <b-form-select-option :value="2"> Chiết khấu </b-form-select-option>
        </b-form-select>
      </template>
      <template v-slot:cell(actions)="row">
        <div class="d-flex justify-content-center">
          <b-dropdown size="sm" no-caret right>
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item @click="showDeleteFeeAlert(row.item)">
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem; color: #d33"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { TIME_TRIGGER } from '../../../utils/constants';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { currencyMask } from '../../../utils/common';
export default {
  props: ['items'],
  data() {
    return {
      renderComponent: true,
      mask: currencyMask,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'rewardCateName',
          label: 'Nhóm sản phẩm',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'amount',
          label: 'Giá trị',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      mainModel: {
        rewardCateId: null,
        rewardCateName: '',
        name: '',
        description: '',
        amount: 0,
        type: 1,
      },
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      isSearching: false,
      listType: [
        {
          id: 1,
          name: 'Chi phí',
        },
        {
          id: 2,
          name: 'Chiết khấu',
        },
      ],
    };
  },
  methods: {
    onSelected(option) {
      this.mainModel.rewardCateId = option.item.id;
      this.mainModel.rewardCateName = option.item.name;
    },
    onInputChange(text) {
      this.mainModel.rewardCateName = text;

      this.debounceInputCate();
    },
    debounceInputCate: decounce(function () {
      const textSearch = this.mainModel.rewardCateName
        ? this.mainModel.rewardCateName.trim()
        : null;
      if (!this.isSearching) {
        this.fetchProductCate(textSearch);
      }
    }, TIME_TRIGGER),
    fetchProductCate(textSearch) {
      this.isSearching = true;
      ApiService.get(`rewardProductCategory/search?name=${textSearch}`).then(
        ({ data }) => {
          this.options[0].data = data.data;
          this.filteredOptions = [
            {
              data: this.options[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.mainModel.rewardCateName) {
            const nextTextSearch = this.mainModel.rewardCateName;
            this.fetchProductCate(nextTextSearch);
          }
        }
      );
    },
    onAddFee: function (input) {
      if (!input.name || !input.rewardCateId) {
        return;
      }

      const fee = {
        count:
          this.items.length === 0
            ? 1
            : this.items[this.items.length - 1].count + 1,
        name: input.name,
        rewardCateName: input.rewardCateName,
        description: input.description,
        amount: input.amount,
        rewardCateId: input.rewardCateId,
        type: input.type,
      };

      this.items.push(fee);

      this.mainModel = {
        rewardCateId: null,
        rewardCateName: '',
        name: '',
        description: '',
        amount: 0,
        type: 1
      };
    },
    showDeleteFeeAlert: function (item) {
      Swal.fire({
        title: 'Xóa chi phí!',
        text: 'Bạn có chắc muốn xóa chi phí này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (!result.dismiss) {
          const details = _.reject(this.items, (fee) => {
            return fee.count == item.count;
          });
          details.forEach((element, index) => {
            element.count = index + 1;
          });
          this.$emit('onDelete', details);
        }
      });
    },
  },
};
</script>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
